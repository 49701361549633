body{
  margin: 0;
  padding: 0;
}
.css-d7l1ni-option{
  background-color: #212142 !important;

}

.css-10wo9uf-option{
  background-color: #000 !important;
}

.MuiInputBase-input{
  color: #000;
}



