.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../Images/Backgrounds/TopCircle.svg") no-repeat;
  background-color: black;
  background-size: 45%;
  color: white;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome {
  font-size: 24px;
  margin-bottom: 10px;
}



.image-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
}

.image-container img {
  max-width: 100%;
  display: block;
}


/* **Project Page Css added **  */

.black-bg {
  height: auto;
  color: white;
  background-color: black;
  padding: 15px;
}

.connected-button{
  border-radius: 5px ;  
  min-width: 250px ;
  background-color: green;
  color: white;
  max-height: 45px !important;
  
}