:root {
  --primary-color: #4f46e5;
  --text-color-black: #1a1d23;
  --text-color-grey: #656e7f;
  --text-color-blue: #4280ff;
  --text-color-white: #ffffff;
  --font-weight-500: 500;
  --font-13: 0.813rem;
  --font-14: 0.875rem;
  --font-15: 0.938rem;
}

* {
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

.main-login-container {
  height: 100vh;
  overflow: hidden;
}

.img-container {
  background: url("../../Assests/Images/Backgrounds/LoginImage.png") no-repeat
    center center;
  background-size: cover;
}

.logo-container {
  height: 32px;
  position: relative;
  top: 100px;
  left: 100px;
  display: flex;
  align-items: center;
}

.logo-container h2 {
  margin-top: 10px;
  margin-left: 7px;
  font-weight: 800;
  font-size: 1.25rem;
  letter-spacing: 0.3px;
  color: var(--text-color-white);
}

.img-overlay {
  color: white;
  text-align: center;
  padding: 20px;
  width: 620px;
  height: 145px;
}

.super-text {
  font-family: "Arima", system-ui;
  font-size: 2.2rem;
}

.img-overlay h2 {
  font-size: 1.75rem;
  line-height: 44px;
  text-align: left;
  letter-spacing: 1px;
}

.list-item-cont {
  display: flex;
  align-items: center;
}

.list-items {
  font-size: var(--font-14);
  font-weight: var(--font-weight-500);
  margin-right: 20px;
}

.heading-small {
  font-size: var(--font-14);
  font-weight: var(--font-weight-500);
  color: var(--text-color-grey);
}

.heading-main {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-color-black);
  margin: 10px 0;
}

.input-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.input-label {
  display: block;
  font-size: var(--font-14);
  margin-bottom: 10px;
  font-weight: var(--font-weight-500);
  color: var(--text-color-black);
}

.custom-input {
  width: 100%;
  padding: 13px 10px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  outline: none;
}

.custom-input:focus {
  border: 1px solid var(--text-color-blue);
}

.password-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 13px 10px;
  box-sizing: border-box;
}

.password-input {
  flex: 1;
  border: none;
  outline: none;
  padding-right: 10px;
}

.password-input-container:focus-within {
  border: 1px solid var(--text-color-blue);
}

.forgot-password-link {
  font-size: var(--font-14);
  color: var(--text-color-blue);
  font-weight: var(--font-weight-500);
  text-decoration: none;
  white-space: nowrap;
  padding-right: 10px;
  cursor: pointer;
}

.ip-placeholder::placeholder {
  font-size: var(--font-13);
  font-weight: 500;
  color: #8792a7;
}

.custom-button {
  width: 100%;
  padding: 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.5px;
}
.custom-button:focus {
  outline: none;
}

.custom-button:hover {
  background-color: #2320c9;
  transition: all 0.2s ease-in-out;
}

.small-text {
  color: var(--text-color-black);
  text-align: center;
  word-spacing: 1px;
  font-size: var(--font-14);
}

.link-text {
  color: var(--text-color-blue);
  cursor: pointer;
}

.link-text-underline {
  color: var(--text-color-blue);
  text-decoration: underline;
  cursor: pointer;
}

.stepper-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-14);
}

.currency-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
}

.currency-box {
  padding: 15px;
  background-color: #f5f7fb;
  color: var(--text-color-grey);
}

.small-main-conatiner {
  flex: 1;
  display: flex;
  justify-content: center;
}

.small-register-container {
  width: 377px;
  height: 182px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small-register-container h2 {
  font-size: 18px;
  text-align: center;
}
.small-register-container p {
  text-align: center;
}
.text-center {
  text-align: center;
}
.dis-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Custom Modal Css */

.modal-input-label {
  font-family: "Instrument Sans";
  display: block;
  font-size: var(--font-15);
  margin-bottom: 8px;
  font-weight: var(--font-weight-500);
  color: #6b7280;
}

/* Drawer */
.selected-option {
  padding: 5px 18px;
  background: #fff;
  border-radius: 8px;
  font-family: "Instrument Sans";
  font-size: 1.5rem;
  font-weight: 500;
}
.unselected-option {
  color: #6b7280;
  background: transparent;
  border-radius: 0;
  font-family: "Instrument Sans";
  font-size: 1.5rem;
  font-weight: 500;
}
.agent-grad-btn {
  outline: none;
  padding: 10px 105px;
  background: white;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #009199, #2c479a);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  position: relative;
  display: flex;
  align-items: center;
}
.agent-grad-btn span {
  background: linear-gradient(91.5deg, #009199 0.05%, #2c479a 100.05%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 18px;
}
button:focus {
  outline: none;
}

.grad-bg-btn {
  outline: none;
  font-family: "Red Hat Display";
  background: linear-gradient(91.5deg, #009199 0.05%, #2c479a 100.05%);
  color: white;
  padding: 13px 64px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.gradient-text-button {
  outline: none;
  padding: 8px 64px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  position: relative;
  background: linear-gradient(to right, #009199, #2c479a);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #009199; /* Fallback color */
  font-weight: 600;
  font-size: 18px;
}

@media (min-width: 900px) {
  .agent-grad-btn {
    padding: 10px 24px;
  }
}
